import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faShieldAlt, faNetworkWired } from '@fortawesome/free-solid-svg-icons';
import './Home.css';

const Home = () => {
  return (
    <>
      <header className="App-header">
        <div className="container">
          <h1>Tule Valley Technology</h1>
          <p>Pioneering Secure and Contextual Large Language Model Solutions</p>
          <a href="#demo" className="cta-button">Request a Demo</a>
        </div>
      </header>
      <section className="features">
        <div className="container">
          <h2>Empowering LLM Integrations with Cutting-Edge Security</h2>
          <div className="feature-grid">
            <div className="feature">
              <FontAwesomeIcon icon={faLock} size="3x" />
              <h3>Fortified Data Security</h3>
              <p>Safeguard sensitive information with our state-of-the-art encryption and access control mechanisms, ensuring the highest level of data protection in your LLM stack.</p>
            </div>
            <div className="feature">
              <FontAwesomeIcon icon={faShieldAlt} size="3x" />
              <h3>Uncompromising Model Integrity</h3>
              <p>Maintain the utmost reliability and trustworthiness of your language models through our rigorous integrity assurance measures, instilling confidence in your AI-driven solutions.</p>
            </div>
            <div className="feature">
              <FontAwesomeIcon icon={faNetworkWired} size="3x" />
              <h3>Intelligent Contextual Guardrails</h3>
              <p>Elevate model performance and mitigate risks with our advanced contextual understanding and control mechanisms, enabling seamless integration of LLMs into your enterprise workflows.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="demo" id="demo">
        <div className="container">
          <h2>Experience the Future of Secure LLMs</h2>
          <p>Request a personalized demo to witness the power of Tule Valley's enterprise-grade solutions firsthand.</p>
          <form className="demo-form">
            <input type="text" placeholder="Name" required />
            <input type="email" placeholder="Email" required />
            <button type="submit">Request Demo</button>
          </form>
        </div>
      </section>
    </>
  );
};

export default Home;
