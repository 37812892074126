import React from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import './App.css';
import Api from './components/Api';
import Pricing from './components/Pricing';
import Playground from './components/Playground';
import Research from './components/Research';
import News from './components/News';
import About from './components/About';
import Home from './components/Home';

const App = () => {
  return (
    <Router>
      <div className="App">
        <nav className="App-nav">
          <div className="container">
            <ul>
              <li>
                <NavLink to="/" end className={({ isActive }) => isActive ? 'active' : ''}>Home</NavLink>
              </li>
              <li>
                <NavLink to="/api" className={({ isActive }) => isActive ? 'active' : ''}>API</NavLink>
              </li>
              <li>
                <NavLink to="/pricing" className={({ isActive }) => isActive ? 'active' : ''}>Pricing</NavLink>
              </li>
              <li>
                <NavLink to="/safetylab" className={({ isActive }) => isActive ? 'active' : ''}>SafetyLab</NavLink>
              </li>
              <li>
                <NavLink to="/research" className={({ isActive }) => isActive ? 'active' : ''}>Research</NavLink>
              </li>
              <li>
                <NavLink to="/news" className={({ isActive }) => isActive ? 'active' : ''}>News</NavLink>
              </li>
              <li>
                <NavLink to="/about" className={({ isActive }) => isActive ? 'active' : ''}>About</NavLink>
              </li>
            </ul>
          </div>
        </nav>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/api" element={<Api />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/safetylab" element={<Playground />} />
          <Route path="/research" element={<Research />} />
          <Route path="/news" element={<News />} />
          <Route path="/about" element={<About />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
