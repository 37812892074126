import React from 'react';
import './Api.css';

const Api = () => {
  return (
    <section id="api">
      <div className="container">
        <h2>API</h2>
        <p>Coming soon...</p>
      </div>
    </section>
  );
};

export default Api;
