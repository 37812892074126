import React from 'react';
import './Research.css';

const Research = () => {
  return (
    <section id="research">
      <div className="container">
        <h2>Research</h2>
        <p>Coming soon...</p>
      </div>
    </section>
  );
};

export default Research;
