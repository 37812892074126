import React from 'react';
import './About.css';

const About = () => {
  return (
    <section id="about">
      <div className="container">
        <h2>About</h2>
        <p>Coming soon...</p>
      </div>
    </section>
  );
};

export default About;
