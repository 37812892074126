import React from 'react';
import './Pricing.css';

const Pricing = () => {
  return (
    <section id="pricing">
      <div className="container">
        <h2>Pricing</h2>
        <p>Coming soon...</p>
      </div>
    </section>
  );
};

export default Pricing;
