import React from 'react';
import './News.css';

const News = () => {
  return (
    <section id="news">
      <div className="container">
        <h2>News</h2>
        <p>Coming soon...</p>
      </div>
    </section>
  );
};

export default News;
